<template>
  <div ref="wheel" @transitionend="transitionHandler" class="wheel">
    <div class="wheel__inner">
      <img svg-inline svg-sprite class="wheel__pie" :src="pie" alt="Pie" />
    </div>
  </div>
</template>

<script>
import pie from "../assets/images/pie.svg";

export default {
  name: "Wheel",
  methods: {
    transitionHandler() {
      this.$emit("transition-end");
    },
  },
  data() {
    return {
      pie,
    };
  },
};
</script>

<style scoped lang="scss">
.wheel {
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 58.8rem;
  height: 58.8rem;
  border-radius: 50%;
  margin-inline: auto;

  background-color: #181818;

  transition: all 2.5s ease-out;
}

.wheel__inner {
  position: relative;

  width: 92.5%;
  height: 92.5%;
  border-radius: 50%;
  margin-inline: auto;

  background-color: #fff;
}

.wheel__pie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 92.5%;
}

@media (max-width: 1440px) {
  .wheel {
    width: 73.5rem;
    height: 73.5rem;
  }
}

@media (max-width: 1180px) {
  .wheel {
    width: 60rem;
    height: 60rem;
  }
}

@media (max-width: 991px) {
  .wheel {
    width: 30rem;
    height: 30rem;
  }
}

@media (max-width: 668px) {
  .wheel {
    width: 25rem;
    height: 25rem;
  }
}
</style>
