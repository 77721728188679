<template>
  <main @click.once="spinHandler" class="selection">
    <div v-show="!isTapped" class="dark-bcg"></div>
    <div v-show="!isTapped" class="overlay-text">Tap to Spin!</div>
    <div class="container">
      <header class="selection__header">
        <img class="selection__logo" :src="logo" alt="Profit and Pursuit" />
      </header>
      <section class="selection__content">
        <div class="selection__wheel">
          <Wheel
            ref="rotatedWheel"
            v-on:transition-end="endTransitionHandler"
          />
          <img class="selection__hand" :src="hand" alt="Hand" />
        </div>
        <div v-show="isCategoryChosen">
          <div ref="category" class="category">
            <h2 class="category__text" v-text="categoryText" />
          </div>
          <span ref="leftArrow" class="category__arrow--left" />
          <span ref="rightArrow" class="category__arrow--right" />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Wheel from "@/components/Wheel";
import routeNames from "@/router/routeNames";
import logo from "../assets/images/icons/logo.svg";
import hand from "../assets/images/hand.svg";

export default {
  name: "Selection",
  components: {
    Wheel,
  },
  data: () => ({
    isTapped: false,
    isCategoryChosen: false,
    chosenAngle: 0,
    zoneSize: 60,
    categoryText: "",
    logo,
    hand,
  }),
  computed: {
    ...mapGetters(["questions", "categories", "chosenCategoryIndexes"]),
    actualDeg() {
      return this.chosenAngle % 360;
    },
    winningCategoryIndex() {
      return Math.ceil(this.actualDeg / this.zoneSize);
    },
    currentCategory() {
      return this.categories.find(
        (category) => category.id === this.winningCategoryIndex
      );
    },
    currentQuestion() {
      return this.questions.find(
        (question) => question.categoryId === this.winningCategoryIndex
      );
    },
    isCategoryAllowed() {
      return (
        this.chosenCategoryIndexes.indexOf(this.winningCategoryIndex) === -1
      );
    },
  },
  methods: {
    getRandomAngle(min, max) {
      return Math.floor(Math.random() * (min - max)) + max;
    },
    customizeCategory(category) {
      this.$refs.category.style.background = category.gradient;
      this.categoryText = category.category;
      this.$refs.leftArrow.style.borderColor = `${category.color} transparent transparent transparent`;
      this.$refs.rightArrow.style.borderColor = `${category.color} transparent transparent transparent`;

      if (category.id === 3) {
        this.$refs.category.style.color = "#32313C";
      }
    },
    winCategoryHandler() {
      this.customizeCategory(this.currentCategory);
      this.$store.dispatch("setCurrentQuestion", this.currentQuestion);
      this.$store.dispatch("setCurrentCategory", this.currentCategory);
      this.goToQuestionHandler();
    },
    spinHandler() {
      this.isTapped = true;

      if (this.chosenCategoryIndexes.length === 5) {
        this.chosenAngle = this.getRandomAngle(2100, 2160);
      } else {
        this.chosenAngle = this.getRandomAngle(2161, 2460);
      }

      if (!this.isCategoryAllowed || this.winningCategoryIndex === 0) {
        this.spinHandler();
      } else {
        this.$store.dispatch("setCategoryIndex", this.winningCategoryIndex);
      }

      this.rotateWheel(this.chosenAngle);
      setTimeout(() => {
        this.isCategoryChosen = true;
      }, 2500);
    },
    endTransitionHandler() {
      this.$refs.rotatedWheel.$refs.wheel.style.transition = "none";
      this.rotateWheel(this.actualDeg);
      this.winCategoryHandler();
    },
    rotateWheel(angle) {
      this.$refs.rotatedWheel.$refs.wheel.style.transform = `rotate(${angle}deg)`;
    },
    goToQuestionHandler() {
      setTimeout(() => {
        this.$router.push(routeNames.question);
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.selection {
  position: relative;

  min-height: inherit;
}

.container {
  width: 100%;
  max-width: 75rem;
  min-height: 100vh;
  padding: 0 1.5rem 3rem;
  margin-inline: auto;
}

.selection__header {
  padding-top: 6.1rem;
  margin-bottom: 4.9rem;
}

.selection__logo {
  display: block;

  max-width: 100%;
  height: auto;
  margin-inline: auto;
}

.selection__content {
  position: relative;
}

.selection__wheel {
  position: relative;

  max-width: 58.8rem;
  width: 100%;
  margin-inline: auto;
}

.selection__hand {
  position: absolute;
  bottom: -3rem;
  left: 7rem;
  z-index: 100;
}

.category {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 66.1rem;
  height: 11rem;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
}

.category__text {
  font-size: 4.8rem;
  font-weight: 700;
}

.category__arrow--left,
.category__arrow--right {
  position: absolute;
  top: 34.8rem;

  display: block;

  width: 0;
  height: 0;
  border-style: solid;
}

.category__arrow--left {
  left: 30px;

  border-width: 5.5rem 0 0 8.75rem;
}

.category__arrow--right {
  right: 30px;

  border-width: 5.5rem 0 0 8.75rem;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.dark-bcg {
  position: absolute;
  z-index: 1001;

  width: 100%;
  height: 100vh;

  background: rgba(1, 0, 10, 0.47);
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1002;
  transform: translate(0, -50%);

  width: 100%;

  font-size: 5.6rem;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 1440px) {
  .container {
    max-width: 82.6rem;
  }

  .selection__wheel {
    max-width: 73.5rem;
  }

  .selection__hand {
    left: 9rem;
  }

  .category {
    width: 82.625rem;
    height: 13.75rem;
  }

  .category__text {
    font-size: 6rem;
  }

  .category__arrow--left,
  .category__arrow--right {
    top: 43.5rem;
  }

  .category__arrow--left {
    left: -12px;
  }

  .category__arrow--right {
    right: -12px;
  }
}

@media (max-width: 1180px) {
  .selection__header {
    margin-bottom: 2rem;
  }

  .selection__hand {
    left: 13rem;
    bottom: -3rem;

    width: 20%;
  }

  .category {
    width: 72.5rem;
  }

  .category__arrow--left,
  .category__arrow--right {
    top: 36.8rem;
  }

  .category__arrow--left {
    left: 28px;
  }

  .category__arrow--right {
    right: 28px;
  }
}

@media (max-width: 991px) {
  .selection__header {
    margin-bottom: 0.5rem;
  }

  .selection__logo {
    max-width: 30rem;
  }

  .selection__wheel {
    max-width: 30rem;
  }

  .selection__hand {
    bottom: -2rem;
    left: 3rem;

    width: 25%;
  }

  .category {
    width: 34rem;
    height: 7rem;
  }

  .category__arrow--left,
  .category__arrow--right {
    top: 18.5rem;
  }

  .category__arrow--left {
    left: 22.7rem;
  }

  .category__arrow--right {
    right: 22.7rem;
  }

  .category__text {
    font-size: 2.4rem;
  }

  .overlay-text {
    font-size: 2.8rem;
  }
}

@media (max-width: 668px) {
  .selection__hand {
    bottom: -1rem;
    left: 5.5rem;

    width: 20%;
  }

  .category {
    width: 30rem;
    height: 5rem;
  }

  .category__arrow--left,
  .category__arrow--right {
    top: 15rem;
  }

  .category__arrow--left {
    left: 25rem;
  }

  .category__arrow--right {
    right: 25rem;
  }

  .category__text {
    font-size: 1.6rem;
  }
}
</style>
